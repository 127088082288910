/* Title */

.title-container {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.title-text {
  color: #4EC1C0;
  margin: 10px 10px 10px 10px;
}

/* Nav */

.top-nav-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 30px;
  padding: 0;
}

.nav-and-logout-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav-button {
  padding: 5px 20px;
  color: #4EC1C0;
  text-decoration: none;
  font-weight: bold;
}

.nav-button.active {
  background-color: #E8FBFA;
}

.logout-button {
  padding: 5px 20px;
  color: #4EC1C0;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  border: none;
  background-color: #E8FBFA;
  cursor: pointer;
}

.sub-nav-container {
  background-color: #E8FBFA;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 30px;
  padding: 0;
}

.sub-nav-button {
  background-color: #E8FBFA;
  padding: 5px 20px;
  color: #4EC1C0;
  text-decoration: none;
  font-weight: bold;
}