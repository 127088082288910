.add-container {
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
}

.remove-button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 16px;
}

.conversation-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.conversation-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.conversation-list li a {
  color: #007bff;
  text-decoration: none;
}

.conversation-list li a:visited {
  color: #007bff;
}